<template>
  <div>
    <!-- Router View untuk konten -->
    <router-view :key="$route.fullPath"></router-view>

    <!-- Privacy Popup -->
    <PrivacyPopup
      v-if="showPrivacyPopup"
      :visible="showPrivacyPopup"
      @agree="handlePrivacyAgree"
      @cancel="handlePrivacyCancel"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'; // Ambil Vuex store
import { computed, onMounted } from 'vue';
import PrivacyPopup from '@/components/popup/PrivacyPopup.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'dash',
  components: {
    PrivacyPopup,
  },
  setup() {
    const { push } = useRouter();
    const { state, dispatch, commit } = useStore(); // Ambil instance store
    const agreePrivacyPolicy = computed(() => state.auth.agreePrivacy);
    const showPrivacyPopup = computed(() => state.auth.showPrivacyPopup); // State Vuex sebagai reactive property

    const handlePrivacyAgree = () => {
      dispatch('agreePrivacyPolicy').then(() => {
        commit('setShowPrivacyPopup', false);
      });
    };

    const handlePrivacyCancel = () => {
      commit('setShowPrivacyPopup', false);
      push('/auth/login');
      dispatch('logOut');
    };

    onMounted(() => {
      dispatch('checkPrivacyPolicy').then(() => {
        if (!agreePrivacyPolicy.value) {
          commit('setShowPrivacyPopup', true);
        }
      });
    });

    return {
      showPrivacyPopup,
      handlePrivacyAgree,
      handlePrivacyCancel,
    };
  },
};
</script>
