<template>
  <div>
    <!-- Modal Kebijakan Privasi -->
    <a-modal
      :visible="visible"
      title="Kebijakan Privasi"
      :confirmLoading="confirmLoading"
      @ok="handleAgree"
      @cancel="handleCancel"
      okText="Setuju"
      cancelText="Batal"
      :maskClosable="false"
    >
      <p>
        Dengan melanjutkan, Anda setuju untuk mematuhi
        <router-link to="/privacy-policy" custom>
          <template v-slot="{ href, navigate }">
            <a :href="href" target="_blank" @click="navigate">kebijakan privasi</a>
          </template>
        </router-link>
        kami. Harap baca kebijakan privasi dengan seksama sebelum melanjutkan.
      </p>
      <p>
        Jika Anda memiliki pertanyaan tentang kebijakan privasi ini, silakan hubungi tim dukungan kami.
      </p>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPopup',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['agree', 'cancel'],
  setup(props, { emit }) {
    const handleAgree = () => {
      emit('agree'); // Emit event 'agree' untuk mencatat persetujuan
    };

    const handleCancel = () => {
      emit('cancel'); // Emit event 'cancel' untuk menutup popup
    };

    return {
      handleAgree,
      handleCancel,
    };
  },
};
</script>
